<template>
  <div
    class="w-work-wrap main-content report-center"
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div
      class="w-work-tabs"
      ref="leftTabs"
      :class="[positonTop ? 'positonTop' : '']"
    >
      <!-- <div
        class="w-work-modular-wrap cursor"
        @click="clickDrawer()"
      >
        <span class="icon-side-hide"></span>报表中心
      </div> -->
      <el-menu
        unique-opened
        :default-active="activeId.toString()"
        :background-color="'#23252b'"
      >
        <template v-for="item in reportCenterList">
          <el-submenu
            v-if="item.hasOwnProperty('dicVOS') && item['dicVOS'].length"
            :index="item.id.toString()"
            :key="item.id"
          >
            <template slot="title">
              {{item.name}}
            </template>
            <el-menu-item
              v-for="item1 in item.dicVOS"
              :key="item1.id"
              :index="item1.id.toString()"
              @click="toSubUrl(item1)"
              :class="{'is-active': item1.id === activeId}"
            >
              {{item1.name}}
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-else
            :key="item.id"
            :index="item.id"
            @click="toSubUrl(item)"
            :class="{'is-active': item.id === activeId}"
          >
            {{item.name}}
          </el-menu-item>
        </template>
      </el-menu>
    </div>
    <el-drawer
      :size="isPx"
      :with-header="false"
      :visible.sync="drawer"
      direction="ltr"
      ref="drawer"
    >
      <Sidebar></Sidebar>
    </el-drawer>
    <div
      v-if="!hasPermission"
      class="jurisdiction-wrap"
    >
      <img
        src="~@/assets/images/Jurisdiction.png"
        alt=""
      >
      <p>暂无权限访问此页，如需开通权限，</p>
      <p>请联系管理员开通</p>
    </div>
    <div
      class="shadePanel"
      v-if="hasPermission && loading"
    ></div>
    <div
      v-show="hasPermission"
      class="iframe-content"
      ref="iframeBox"
    >
      <iframe
        :src="subUrl"
        id="commandIframe"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Sidebar from '@/components/Sidebar.vue'
import api from '@/api'
export default {
  components: {
    Sidebar
  },
  data () {
    return {
      drawer: false,
      loading: true,
      routePath: '',
      isPx: '',
      positonTop: false,
      reportCenterList: [],
      subUrl: '',
      hasPermission: true,
      urlGetUrl: '',
      urlGetCid: '',
      activeId: ''
    }
  },
  watch: {
    '$route.path': function (newVal, oldVal) {
      this.routePath = newVal
    }
  },
  computed: {
    ...mapGetters({
      token: 'token'
    }),
    url () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8080'
      }
      return temUrl
    }
  },
  created () {
    this.routePath = this.$route.path
    this.urlGetUrl = this.$route.query.url
    this.urlGetCid = parseInt(this.$route.query.cid)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
    document.body.style.overflowY = 'auto'
  },
  mounted () {
    this.getReportCenterList()
    const clientWidth = `${document.documentElement.clientWidth}`
    if (clientWidth > 1500) {
      this.isPx = '516px'
    } else {
      this.isPx = '448px'
    }
    const iframe = document.getElementById('commandIframe')
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', () => {
        // iframe加载完成后要进行的操作
        this.loading = false
      })
    } else {
      iframe.onload = () => {
        // iframe加载完成后要进行的操作
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    }
  },
  methods: {
    chargeSubUrl (item) {
      if (item.url.indexOf('/BI/') !== -1) { // 高德跳转方式
        this.subUrl = this.$gaodeDomain + item.url + '?tokenval=' + this.token + '&id=' + item.id
        document.body.style.overflowY = 'hidden'
        this.$refs.iframeBox.style.height = document.body.clientHeight - 150 + 'px'
        this.$refs.leftTabs.style.height = document.body.clientHeight - 133 + 'px'
      } else {
        const arrURL = item.url.split('com/set/')
        this.subUrl = this.$shulieDomain + arrURL[1] + '&token=' + this.token
        document.body.style.overflowY = 'auto'
        this.$refs.iframeBox.style.height = '820px'
        this.$refs.leftTabs.style.height = '850px'
      }
      document.body.scrollIntoView()
    },
    toSubUrl (item) {
      if (this.activeId !== item.id) { // 避免进入当前页
        this.activeId = item.id
        window.location.href = this.url + '/#/reportCenter?url=' + item.url + '&cid=' + item.id
        this.subUrl = ''
        if (item.url) { // 存在url则有权限否则无
          this.hasPermission = true
          this.loading = true
          this.chargeSubUrl(item)
        } else {
          this.hasPermission = false
          this.loading = false
        }
      }
    },
    getReportCenterList () {
      const params = {
      }
      this.axios.post(api.getBBList, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          let itemList = [] // 有权限的列表
          let activeItem = [] // 有权限的第一条
          this.reportCenterList = resData.map((item) => {
            return item
          })
          if (!this.urlGetUrl && this.urlGetUrl !== 'null' && !this.urlGetCid) { // 1.url不存在参数，header导航进入的情况
            itemList = resData.filter(item => { // 取侧边栏有权限的一级菜单项列表
              return item.url
            })
            if (itemList.length) { // 当存在有权限的子项时,取有权限第一条
              activeItem = [...itemList].shift()
              activeItem.id = activeItem.cid // 如果存在此项，那么activeItem.id 取子项的有权限的项
              this.activeId = activeItem.cid
            } else { // 否则不存在有权限的子项，那么取所有第一条
              activeItem = [...resData].shift()
              this.activeId = [...activeItem.dicVOS].shift().id
            }
          } else if (this.urlGetCid) { // 2.当url存在参数（当前页面刷新的情况）
            this.activeId = this.urlGetCid
            if (this.urlGetUrl && this.urlGetUrl !== 'null') { // 侧边栏跳转页面有权限的情况
              activeItem.url = this.urlGetUrl
              activeItem.id = this.urlGetCid
            } else { // url参数为false值，侧边栏跳转页面但跳转页面无权限的情况
              this.hasPermission = false
            }
          } else { // 其他情况
            this.hasPermission = false
          }
          if (activeItem.url) {
            this.chargeSubUrl(activeItem)
          } else {
            this.hasPermission = false
            this.loading = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleScroll (e) {
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (scrollTop > 88) {
        this.positonTop = true
      } else {
        this.positonTop = false
      }
    },
    clickDrawer () {
      this.drawer = true
    }
  }
}
</script>

<style lang="stylus" scoped>
.jurisdiction-wrap
  height 650px
  background #23252a
  padding-top 200px
.main-content
  .iframe-content
    width calc(100% - 246px)
    margin-left 244px
    margin-top 10px
    margin-bottom 30px
    height 820px
    background-color #1e1e23
    iframe
      width 100%
      height 100%
.w-work-wrap
  .w-work-tabs
    position absolute
    width 220px
    height calc(100% - 130px)
    // background-color #272930
    background-color #23252B
    box-shadow 32px 0px 13px 0px rgba(18, 19, 25, 0.5)
    overflow-y auto
    .w-work-modular-wrap
      height 34px
      line-height 33px
      font-size 16px
      font-weight 500
      color #FFFFFF
      .icon-side-hide
        width 40px
        text-align center
        color #fff
        background-color rgba(255, 255, 255, 0.1)
        border-right 1px solid rgba(255, 255, 255, 0.2)
        margin-right 20px
.isAcitve
  background-color rgba(255, 255, 255, 0.17) !important
  border-left 3px solid #FFA134 !important
  color #fff !important
.w-work-wrap
  .positonTop
    position fixed
    left 0
    top 0 !important
    height 100%
    z-index 11
    ul
      min-height 100%
      background-color #23252B
      margin-top 10px
.submenu-menu
  ul li
    height 40px
    line-height 40px
    color rgba(165, 165, 167, 1)
    font-size 14px
    padding-left 30px
    cursor pointer
    border-left 3px solid transparent
    &:hover
      background-color rgba(255, 255, 255, 0.07)
      border-left 3px solid #FFA134
    &.router-link-exact-active
      background-color rgba(255, 255, 255, 0.07)
      border-left 3px solid #FFA134
      color #fff
.shadePanel
  width 100%
  height 850px
.el-menu>>>
  border none
  .el-menu-item.is-active
    color #fff !important
  .el-submenu
    .is-active
      background-color rgba(255, 255, 255, 0.17) !important
      border-left 3px solid #FFA134 !important
      color #fff !important
  .el-submenu__title
    color #fff !important
  .el-menu-item
    color #A5A5A7 !important
</style>
